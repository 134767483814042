import React from "react";
import logo from "../images/main-logo.png";
import b from "../images/4.jpg";
import b1 from "../images/b1.png";
import b2 from "../images/b2.png";
import b3 from "../images/b3.jpg";
import b4 from "../images/b4.png";
import b5 from "../images/b5.png";
import img2 from "../images/2.jpg";
import p1 from "../images/p1.png";
import p2 from "../images/p2.png";
import p3 from "../images/p3.png";
import p4 from "../images/p4.png";
import img3 from "../images/1.jpg";
import img4 from "../images/3.jpg";
import img5 from "../images/7.jpg";
import img6 from "../images/8.avif";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import PaidIcon from "@mui/icons-material/Paid";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const Body = () => {
  return (
    <div>
      <div className="body1">
        <div className="b1a">
          <div className="head1">The financial control centre to manage</div>
          <div className="head2">spending</div>
          <p className="head3">
            A modern business account designed to save money with corporate
            cards, credit, money transfers, expense reimbursements, and
            automated accounting—all in one place.
          </p>
          {/* <div className="b1c">
            <img src={b1} alt="" />
            <img src={b2} alt="" />
            <img src={b3} alt="" />
            <img src={b4} alt="" />
            <img src={b5} alt="" />
          </div> */}
        </div>
        <div className="b1b">
          <img src={b} alt="" />
        </div>
      </div>
      <div className="body2">
        <div className="b2a">
          <div className="b2b">
            <img src={p1} alt="" />
            <img src={p2} alt="" />
          </div>
          <div className="b2b">
            <img src={p3} alt="" />
            <img src={p4} alt="" />
          </div>
        </div>
        <div className="head3 he3">
          Trusted by finance teams at startups to enterprises.{" "}
          <span className="he5">Meet our customers</span> →
        </div>
      </div>
      <div className="body3">
        <div className="head4">A fully integrated suite of financial tools</div>
        <p className="head3">
          Volopay is a purpose-built spend management platform that helps you
          escape painful admin work, lack of control and blinkered visibility.
          We bring together everything that's required to make payments for a
          business ranging from software subscriptions to petty cash, business
          travel to online advertising, employee reimbursements to vendor
          payouts.
        </p>
      </div>
      <div className="body4">
        <img src={img2} alt="" />
      </div>
      <div className="body5">
        <div className="b5">
          <div className="head5">Spend smarter and faster</div>
          <p className="head3">
            Make business payments online and offline with secure cards. Volopay
            physical and virtual prepaid cards for each employee allow faster
            payments without any delays.
          </p>
          <p className="head3">
            Smart features like custom spending limits for each prepaid card
            make sure that there is no overspending.
          </p>
        </div>
        <div>
          <img src={img3} alt="" />
        </div>
      </div>
      <div className="body6">
        <div>
          <p className="head5">The corporate card every employee wants!</p>
          <p className="head3">
            Issue physical & virtual cards to employees. Control, track and set
            spending rules for each card issued.
          </p>
        </div>
        <div className="b6">
          <div className="b61">
            <div className="b61a">
              <CreditCardIcon fontSize="large" />
            </div>
            <div className="he6">Corporate physical card</div>
            <div className="head3">
              Get physical corporate cards for employees to make in-person
              payments. Perfect for business travelers, salespersons, and
              operational staff.
            </div>
            <div className="he61">Learn more →</div>
          </div>
          <div className="b61">
            <div className="b61a">
              <CloudQueueIcon fontSize="large" />
            </div>
            <div className="he6">Corporate virtual cards</div>
            <div className="head3">
              Use our USD virtual cards to spend internationally without
              incurring FX charges on each transaction.
            </div>
            <div className="he61">Learn more →</div>
          </div>
        </div>
      </div>
      <div className="body5 b7">
        <div className="b5">
          <div className="head5">
            Streamline your payables, embrace automation
          </div>
          <p className="head3">
            Create vendor accounts on Myntpay and use our system to pay invoices
            seamlessly. Vendor account creation saves you from entering their
            payment information every time a payment has to be made.
          </p>
          <p className="head3">
            Automation features such as scheduling and recurring payments help
            streamline your accounts payable process.
          </p>
        </div>
        <div>
          <img src={img4} alt="" />
        </div>
      </div>
      <div className="body5 b7">
        <div>
          <img src={img5} alt="" />
        </div>
        <div className="b5">
          <div className="head5">Advanced multi-level approval workflows</div>
          <p className="head3">
            Myntpay’s accounts payable module has a maker-checker function in
            the form of multi-level approval workflows. This ensures that your
            organization doesn’t lose money to hackers, data loss, internal
            fraud, errors, and/or payment fraud.
          </p>
          <p className="head3">
            Set custom parameters for money transfers to go through approval
            workflows & ensure secure payments.
          </p>
        </div>
      </div>
      <div className="body5 b7">
        <div className="b5">
          <div className="head5">Easily reimburse out-of-pocket expenses</div>
          <p className="head3">
            Myntpay makes reimbursements easier by allowing employees to
            instantly submit expense claims instead of waiting till the end of
            the month to submit expense reports. This can be done through our
            web app or mobile app.
          </p>
          <p className="head3">
            You also get the ability to attach receipt images to each expense
            and any additional notes for reconciliation.
          </p>
        </div>
        <div>
          <img src={img6} alt="" />
        </div>
      </div>
      <div className="body6">
        <div>
          <p className="head5">Global remittance at your fingertips</p>
          <p className="head3">
            Send money to more than 130 countries worldwide with SWIFT &
            non-SWIFT payment options. Process your vendor payouts and employee
            reimbursements easier than ever before, at the lowest possible
            rates!
          </p>
        </div>
        <div className="b6">
          <div className="b61">
            <div className="b61a">
              <PermIdentityIcon fontSize="large" />
            </div>
            <div className="he6">Vendor payouts</div>
            <div className="head3">
              Transfer money domestically to vendors and suppliers using
              NEFT/RTGS/IMPS/UPI payment methods.
            </div>
            <div className="he61">Learn more →</div>
          </div>
          <div className="b61">
            <div className="b61a">
              <PaidIcon fontSize="large" />
            </div>
            <div className="he6">Reimbursements</div>
            <div className="head3">
              Easily reimburse your employees for all their business expenses
              without making them wait till the end of the month to submit
              expense reports.
            </div>
            <div className="he61">Learn more →</div>
          </div>
        </div>
      </div>
      <div className="body8">
        <p className="head5">Seamless integrations, Simplified accounting</p>
        <p className="head3">
          Myntpay is a dynamic platform that is capable of integrating with any
          accounting software thanks to its Universal CSV feature. It also has
          native integrations with industry-leading accounting tools like Tally,
          Netsuite, Xero, Quickbooks, MYOB, Deskera, and more!
        </p>
      </div>
      <div className="body9">
        <div>
          <p className="head5">Financial stack for business</p>
          <p className="head3 b9">
            No more switching between multiple bank accounts and tools to run
            your smart business. We have combined great software with every
            service you are using a bank for, to produce a state-of-the-art
            financial platform. Corporate Cards Empower your employees with
            physical and virtual cards
          </p>
        </div>
        <div className="b9a">
          <div className="b9b">
            <div className="b9c">
              <PermIdentityIcon fontSize="large" />
              <div className="a1">Corporate Cards</div>
              <div className="a2">
                Empower your employees with physical and virtual cards
              </div>
            </div>
            <div className="b9c">
              <PermIdentityIcon fontSize="large" />
              <div className="a1">Expense Management</div>
              <div className="a2">
                Manage and track every dollar that leaves your company
              </div>
            </div>
          </div>
          <div className="b9b">
            <div className="b9c">
              <PermIdentityIcon fontSize="large" />
              <div className="a1">Business Bank Account</div>
              <div className="a2">
                Open a global account with multi-currency payments
              </div>
            </div>
            <div className="b9c">
              <PermIdentityIcon fontSize="large" />
              <div className="a1">Accounts Payables</div>
              <div className="a2">
                Manage vendors, approve invoices and automate payments
              </div>
            </div>
          </div>
          <div className="b9b">
            <div className="b9c">
              <PermIdentityIcon fontSize="large" />
              <div className="a1">Accounting Automation</div>
              <div className="a2">
                Save hours daily, and close your books faster every month
              </div>
            </div>
            <div className="b9c">
              <PermIdentityIcon fontSize="large" />
              <div className="a1">Integrations</div>
              <div className="a2">
                Integrate with the all the tools and software that you use daily
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="body10">
        <div className="logo">
          <img className="main-logo" src={logo} alt="M" />
          <a className="navbar-brand" href="#">
            Myntpay
          </a>
        </div>
        <div className="b10">
          <ul>
            <li>
              <h5>Features</h5>
            </li>
            <li>Subscription management</li>
            <li>Prepaid cards</li>
            <li>Virtual cards</li>
            <li>Vendor payouts</li>
            <li>Invoice management</li>
            <li>International money transfer</li>
            <li>Global business account</li>
          </ul>
        </div>
        <div className="b10">
          <ul>
            <li>
              <h5>Product</h5>
            </li>
            <li>Corporate cards</li>
            <li>Accounting automation</li>
            <li>Accounts payable</li>
            <li>Business account</li>
            <li>Integration</li>
            <li>Expense management</li>
          </ul>
        </div>
        <div className="b10">
          <ul>
            <li>
              <h5>Company</h5>
            </li>
            <li>About us</li>
            <li>Pricing</li>
            <li>Resources</li>
            <li>Privacy policy</li>
            <li>Terms</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Body;
