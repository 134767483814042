import React from "react";
import logo from "../images/main-logo.png";
import flag from "../images/flag.png";

const Navbar = () => {
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container-fluid">
          <div className="logo">
            <img className="main-logo" src={logo} alt="M" />
            <a className="navbar-brand" href="#">
              Myntpay
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown">
                <a
                  class="nav-link active dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Product
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" href="#">
                      Corporate Cards
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Business Bank Account
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Expense Management
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Accounting Automation
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Integrations
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Accounts Payables
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">
                  Resources
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">
                  Pricing
                </a>
              </li>
            </ul>

            <div className="login">
              <div className="l">
                <button type="button" className="btn btn-light">
                  Log in
                </button>
              </div>
              <div className="l">
                <button
                  style={{ backgroundColor: "#1c0453", color: "white" }}
                  type="button"
                  className="btn"
                >
                  Sign up
                </button>
              </div>
              <div className="l">
                <img className="flag" src={flag} alt="" />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
